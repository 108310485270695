.App-canvas-container {
  margin: 0 auto;
  flex: auto;
  display: flex;
  flex-direction: column;
  background: 'offwhite';
}

.App-canvas {
  width: 100%;
  height: 100%;
  flex: 1;
}
