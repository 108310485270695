.App-footer {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #282c34;
  color: white;
}
