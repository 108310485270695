/* button class silver rounded corners and hover effect */
.App-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* for larger screen size */
@media (min-width: 768px) {
  .App-button-container {
    justify-content: center;
  }
}