/* button class silver rounded corners and hover effect */
.App-button {
  padding: 1rem 32px;
  max-width: 40vw;

  flex: 1;

  background-color: #fcecfe;
  border: 2px solid #7E767F;
  border-radius: 11px;

  color: #7E767F;
  font-size: 20px;

  cursor: pointer;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;


}

/* for larger screen */
@media (min-width: 768px) {
  .App-button {
    max-width: 200px;
    margin: 0 10px;
  }
}

/* hover */
.App-button:hover,
.App-button:focus {
  background-color: #191719;
  color: #fcecfe;
}