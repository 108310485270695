.App-logo-wrapper {
  position: absolute;
  top: 0;
  left: 20px;
  height: 100px;
  width: 100px;
  display: flex;
  color: white;
}

/* for larger display */
@media (min-width: 768px) {
  .App-logo-wrapper {
    top: initial;
    bottom: 0;
  }
}

.App-logo-container {
  margin: 25px;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  font-size: 25px;
  line-height: 0;
}

.App-logo-row {
  margin: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* .App-logo-row flexbox items are spaced around horizontally */
.App-logo-row > span {
  flex: 1;
}