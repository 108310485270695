body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  font-family: sans-serif;
  background-color: #FAF9F6; /* https://htmlcolorcodes.com/colors/off-white/ */
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.App-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  flex: 1;
  display: flex;
  flex-direction: column;
}
